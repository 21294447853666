<template>
  <div class="p-4">
    <div class="flex gap-2 items-center mb-2 fs-18">
      <span
        class="text-black font-bold fs-18 cursor-pointer"
        @click="handleBackToList"
      >{{ $t('lbl_list_circle') }}</span>
      /
      <span
        @click="handleBackToDetail"
        class="text-black font-bold fs-18 cursor-pointer"
      >{{ $t('lbl_circle_information') }}</span>
      /
      <span class="fs-16">{{ $t('lbl_livestream_room') }}</span>
    </div>
    <div
      v-if="(isMember || isAdmin)  && circleData&&circleData.live_room_id"
      class="bg-white p-4 rounded-lg container mx-auto"
    >
      <div class="flex flex-col gap-2 md:flex-row justify-between items-start">
        <div
          class="fs-16 text-black mb-4 flex-1 break-words"
        >{{ $t('lbl_our_livestream_room_is_a_reliable_destination_for_transmitting_medical_events_and_important_healthcare_messages__enhancing_understanding_and_awareness_within_the_healthcare_community_') }}</div>

        <div
          v-if="
                (isMember || isAdmin) &&
                circleData &&
                (circleData.type === CIRCLE_TYPE.LIVE || circleData.live_room_id)
              "
          class="open-chat-att"
          @click="goToStream"
        >
          <div class="d-flex align-items-center">
            <img
              class="mr-2"
              :src="liveStream"
              :alt="circleData && circleData.name"
              width="32"
              height="32"
            />
            <div>
              <span
                class="inline-block livestream-redot mb-0"
                v-if="circleData && circleData.is_live_stream === 2"
              >{{ $t('lbl_on_air') }}</span>
              <span class="block txt-black fs-16 fw-500">
                {{
                (user_100ms_role === "presenter" || user_100ms_role === "broadcaster") ? $t("circle_feature.lbl_circle_live") : circleData && circleData.is_live_stream === 2 ? $t("circle_feature.lbl_circle_live_watch_now") : $t("circle_feature.lbl_circle_live_watch")
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <CircleSchedule
        v-if="circleData"
        class="col-12"
        :isAdmin="isAdmin"
        :seedMember="circleData && circleData.seed"
      />
      <CircleLiveMembers :circleData="circleData" :isAdmin="isAdmin" />
    </div>
    <div v-else class="p-4 flex flex-col gap-2 items-center justify-center">
      <div
        v-if="isAdmin"
        class="fs-18 text-black text-center"
      >{{ $t('lbl_your_circle_has_no_livestream_room_please_press_create_to_initiate_a_room_') }}</div>

      <div v-else class="fs-18 text-black">{{ $t('lbl_your_circle_has_no_livestream_room') }}</div>
      <button
        v-if="isAdmin"
        @click="createLiveRoom"
        class="btn bg-blue-900 hover:bg-blue-900 text-white"
      >
        <span class="mr-2 fs-24">+</span>
        {{ $t('circle_feature.lbl_circle_create_live_room') }}
      </button>
    </div>
  </div>
</template>

<script>
import appUtils from '../../../utils/appUtils'
import { CIRCLE_TYPE } from '../../../utils/constants'
import CircleSchedule from '../../Circle/CircleSchedule.vue'
import CircleLiveMembers from './CircleLiveMembers.vue'
import liveStream from '@/assets/images/livestream.png'

export default {
  name: 'CircleLiveDetail',
  components: { CircleSchedule, CircleLiveMembers },
  data () {
    return {
      user_100ms_role: 'viewer',
      circle_member: {},
      circleData: {},
      isLoading: false,
      CIRCLE_TYPE,
      liveStream
    }
  },
  computed: {
    isMember () {
      return !!this.circle_member && this.circle_member.status === 2
    },
    isAdmin () {
      return (
        (!!this.isMember && this.circle_member.role === 1) ||
        this.circleData?.created_by === this.$user?.id
      )
    },
    isMyHasAssignRole () {
      return ![null, '', 'viewer', 'guest', 'hls-viewer'].includes(
        this.circle_member?.one_hundred_ms_role
      )
    }
  },
  created () {
    this.handlePrepare()
  },

  methods: {
    handleBackToList () {
      this.$router.replace({
        name: 'CircleList'
      })
    },
    handleBackToDetail () {
      if (!this.$route.params.id) return

      this.$router.replace({
        name: 'CircleDetail',
        params: {
          id: this.$route.params.id
        }
      })
    },
    async handleGetCircleDetail () {
      if (!this.$route.params.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .showCircle(this.$route.params.id)

        this.circleData = response.data || {}

        return response.data
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('circle_feature.lbl_permission_msg'),
          type: 'warning'
        })
      }
    },
    handleOpenEditModal () {
      this.$refs.ModalEditCircle.handleOpen(this.circleData)
    },
    async getCurrentMember () {
      if (!this.$user?.id) return

      const self = this
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getMemberByUserId(this.$route.params.id, this.$user.id, null, false)
        .then(async r => {
          if (
            self.circleData?.created_by === self.$user?.id &&
            !r.data?.one_hundred_ms_role
          ) {
            const role = {
              livestream: 'broadcaster'
            }
            const res = await self.assignMember(
              r.data?.id,
              JSON.stringify(role)
            )
            self.circle_member = {
              ...r.data,
              one_hundred_ms_role:
                typeof res.data.one_hundred_ms_role === 'string' &&
                appUtils.isJSON(res.data.one_hundred_ms_role)
                  ? JSON.parse(res.data.one_hundred_ms_role || '{}')
                  : res.data.one_hundred_ms_role
            }
            return r
          }

          const role =
            typeof r.data?.one_hundred_ms_role === 'string' &&
            appUtils.isJSON(r.data?.one_hundred_ms_role)
              ? JSON.parse(r.data?.one_hundred_ms_role)
              : {
                livestream: 'viewer'
              }
          if (
            typeof r.data?.one_hundred_ms_role === 'string' &&
            !appUtils.isJSON(r.data?.one_hundred_ms_role)
          ) {
            await self.assignMember(r.data?.id, JSON.stringify(role))
          }

          self.circle_member = {
            ...r.data
          }

          return r
        })
        .catch(() => {
          let r = {
            data: null
          }
          return r
        })
      return r?.data
    },
    async handlePrepare () {
      this.isLoading = true

      try {
        await this.handleGetCircleDetail()
        const circleMemberResponse = await this.getCurrentMember()

        if (circleMemberResponse?.status === 0) return // NOT org member

        this.get100msRole()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async get100msRole () {
      if (!this.circleData?.id || !this.$user?.id) return

      await this.$rf
        .getRequest('DoctorRequest')
        .checkMyRoleConferenceRoom({
          circle_id: this.circleData.id,
          user_id: this.$user.id
        })
        .then(res => {
          this.user_100ms_role =
            res.data?.one_hundred_ms_role ||
            JSON.stringify({
              livestream: 'viewer'
            })
        })
        .catch(e => {
          console.log(e)
          this.$toast.open({
            message: e,
            type: 'error'
          })
        })
    },
    async createLiveRoom () {
      try {
        const self = this

        if (!this.circleData?.id) {
          self.$toast.open({
            message: self.$t('lbl_circle_id_are_not_found'),
            type: 'error'
          })
          return
        }
        if (self.circleData?.live_room_id) {
          self.$toast.open({
            message: self.$t(
              'lbl_live_stream_has_already_existed__you_cannot_add_another_one'
            ),
            type: 'error'
          })
          return
        }
        if (!this.isAdmin) {
          self.$toast.open({
            message: self.$t(
              'lbl_you_do_not_have_permission_to_add_live_stream'
            ),
            type: 'error'
          })
          return
        }

        if (!self.circle_member) {
          await self.getCurrentMember()
        }

        await self.$rf
          .getRequest('DoctorRequest')
          .createLiveRoom(this.circleData.id)
          .then(async () => {
            self.$toast.open({
              message: self.$t('lbl_create_live_stream_successfully'),
              type: 'success'
            })

            const role = {
              livestream: 'broadcaster',
              conference: null
            }
            const res = await self.assignMember(
              self.circle_member?.id,
              JSON.stringify(role)
            )

            self.circle_member = {
              ...self.circle_member,
              one_hundred_ms_role: JSON.parse(
                res?.data?.one_hundred_ms_role || '{}'
              )
            }

            await self.handlePrepare()
          })
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('lbl_failed_to_initialize_livestream_room'),
          type: 'error'
        })
      }
    },
    async assignMember (circle_member_id, role_name) {
      try {
        const roleRaw =
          typeof role_name === 'string' && appUtils.isJSON(role_name)
            ? JSON.parse(role_name)
            : {
              livestream: role_name
            }
        const member_role_ms =
          typeof this.circle_member?.one_hundred_ms_role === 'string' &&
          appUtils.isJSON(this.circle_member?.one_hundred_ms_role)
            ? JSON.parse(this.circle_member?.one_hundred_ms_role)
            : {}
        const role = {
          ...member_role_ms,
          ...roleRaw
        }
        const params = {
          circle_member_id: circle_member_id,
          role: JSON.stringify(role)
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .assignConferenceRole(params)
        return res.data
      } catch (error) {
        console.log(error)
      }
    },
    async goToStream () {
      if (!this.circleData.live_room_id) {
        alert(this.$t('circle_feature.lbl_live_circle_not_exist'))
        return
      }

      const { id, live_room_id } = this.circleData
      await this.get100msRole().then(() => {
        let token = appUtils.getLocalToken()
        let domainOrigin = window.location?.origin
        let domain = ''

        if (domainOrigin?.includes('http://localhost')) {
          domain = 'http://localhost:8068'
        } else if (process.env.NODE_ENV === 'development' && !domainOrigin?.includes('http://localhost')) {
          domain = 'https://dev.live.hodo.app'
        } else {
          domain = 'https://live.hodo.app'
        }

        const roleObj = appUtils.isJSON(this.user_100ms_role)
          ? JSON.parse(this.user_100ms_role)
          : {
            livestream: this.user_100ms_role
          }
        const role = roleObj['livestream']
        const routeRaw = `${domain}/redirect.html?token=${token}&room_id=${live_room_id}&circle_id=${id}&type=streaming&role=${role ||
          'viewer'}&lang=${this.$lang}`

        console.log(routeRaw)

        let linkElement = document.createElement('a')
        const linkId = `conf_${live_room_id}`
        linkElement.id = linkId
        linkElement.href = routeRaw
        window.document.body.appendChild(linkElement)

        const linkEl = document.getElementById(linkId)
        linkEl.setAttribute('rel', 'noopener noreferrer')
        !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
        linkEl.click()
        linkEl.remove()

        // window.open(routeRaw, '_blank')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.open-chat-att {
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eff4ff;
  }
}

.livestream-redot {
  background-color: #d92d20;
  color: white;
  padding: 1px 8px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
}
</style>
